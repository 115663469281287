import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {IResourceMethod, ResourceAction, ResourceParams} from '@ngx-resource/core';

/**
 Class for the ProjetosService.
 */

@Injectable()
@ResourceParams({
  url: BaseService.WP_BASE_URL + '/v2/projetos'
})
export class ProjetosService extends BaseService {
  @ResourceAction({
    path: '/13',
    params: {'_embed': true},
    url: BaseService.WP_BASE_URL + '/v2/pages'
  })
  getPage: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/{!id}',
    params: {'_embed': true}
  })
  get: IResourceMethod<any, any>;

  @ResourceAction({
    params: {'_embed': true},
    returnRaw: true
  })
  query: IResourceMethod<any, any>;
}
